import React from 'react';
import SplashCard from '../components/SplashCard';
import SplashNoCard from '../components/SplashNoCard';
import SplashMainCard from '../components/SplashMainCard';
import SplashAlbumSection from '../components/SplashAlbumSection';
import SpashRollingTextSection from '../components/SpashRollingTextSection';

function Home() {
  return (
    <div className="w-full max-w-4xl mx-auto">
      <SplashCard background="app-colors-bg">
        <SplashMainCard />
      </SplashCard>
      <SplashNoCard>
        <SplashAlbumSection />
      </SplashNoCard>
      <SplashCard background="gradient-bg-red">
        <SpashRollingTextSection />
      </SplashCard>
    </div>
  );
}

export default Home;

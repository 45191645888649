import config from '../config';
import appStoreBadge from '../images/app-store-badge.svg';
function AppStoreBadge() {
  const appStoreURL = config.appStoreURL;
  return (
    <a href={appStoreURL} className="download-link">
      <img
        src={appStoreBadge}
        alt="App Store Badge"
        className="badge w-40 h-auto"
      />
    </a>
  );
}

export default AppStoreBadge;

import React, { useState, useEffect } from 'react';

import albumImage1 from '../images/album-images/album-image-1.jpg';
import albumImage2 from '../images/album-images/album-image-2.jpg';
import albumImage3 from '../images/album-images/album-image-3.jpg';
import albumImage4 from '../images/album-images/album-image-4.jpg';
import albumImage5 from '../images/album-images/album-image-5.jpg';
import albumImage6 from '../images/album-images/album-image-6.jpg';
import albumImage7 from '../images/album-images/album-image-7.jpg';
import albumImage8 from '../images/album-images/album-image-8.jpg';
import albumImage9 from '../images/album-images/album-image-9.jpg';
import albumImage10 from '../images/album-images/album-image-10.jpg';
import albumImage11 from '../images/album-images/album-image-11.jpg';
import albumImage12 from '../images/album-images/album-image-12.jpg';

function AlbumHeader({ subHeader }) {
  return (
    <div className="content flex flex-col items-center w-full text-snow-white">
      <p className="header mb-4">{subHeader}</p>
    </div>
  );
}

function AlbumContainer({ images, title }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const interval = 600;

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => {
      clearInterval(imageInterval);
    };
  }, [images.length]);

  return (
    <div className="album-container">
      <div className="album-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Album ${index + 1}`}
            className={`album-image ${
              index === currentImageIndex ? 'active' : ''
            }`}
            style={{
              opacity: index === currentImageIndex ? 1 : 0
            }}
          />
        ))}
      </div>
      <div className="album-name">
        <h1>{title}</h1>
      </div>
    </div>
  );
}

function MessageBubble({ message, position, direction }) {
  return (
    <div className={`message-bubble-container message-bubble-${position}`}>
      <div className={`message-bubble message-bubble-${direction}`}>
        <p className="message-text">{message}</p>
      </div>
    </div>
  );
}

function SplashAlbumSection() {
  const albumImages = [
    albumImage1,
    albumImage2,
    albumImage3,
    albumImage4,
    albumImage5,
    albumImage6,
    albumImage7,
    albumImage8,
    albumImage9,
    albumImage10,
    albumImage11,
    albumImage12,
  ];

  const albumSectionSubHeader = 'the whole roll, unfiltered';
  const albumTitle = 'twin brat summer';
  const albumMessageBubbleTopRight = '💙💛';
  const albumMessageBubbleTopLeft = 'hotties 😍';
  const albumMessageBubbleBottomRight = 'you really twins?? 🤯';
  return (
    <>
      <AlbumHeader subHeader={albumSectionSubHeader} />
      <div className="album-bubble-container">
        <AlbumContainer images={albumImages} title={albumTitle} />

        <MessageBubble
          message={albumMessageBubbleTopRight}
          position="top-right"
          direction="right"
        />

        <MessageBubble
          message={albumMessageBubbleTopLeft}
          position="top-left"
          direction="left"
        />

        <MessageBubble
          message={albumMessageBubbleBottomRight}
          position="bottom-right"
          direction="right"
        />
      </div>
    </>
  );
}

export default SplashAlbumSection;

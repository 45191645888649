import React from 'react';

function SplashCard({ background, children }) {
  return (
    <div className={`card ${background} py-12 m-4`}>
      <div className="flex flex-col items-center justify-center w-full px-4">
        {children}
      </div>
    </div>
  );
}

export default SplashCard;
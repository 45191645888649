import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';

const Header = () => (
  <header className="bg-black text-snow-white py-6 px-4">
    <div className="container mx-auto flex justify-between items-center">
      <Link to="/" className="flex-shrink-0">
        <img src={logo} alt="App Logo" className="logo h-8" />
      </Link>
      <nav className="flex items-center">
        <Link to="/about" className="site-link">
          about
        </Link>
      </nav>
    </div>
  </header>
);

export default Header;

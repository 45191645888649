import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProfilePicture from './ProfilePicture';
import { api, APIEndpoints } from '../services/api';
import AppStoreBadge from '../components/AppStoreBadge';

const AlbumHeader = ({ name, creator, profilePic, description }) => (
  <div className="flex items-start mb-8">
    <ProfilePicture src={profilePic} alt={creator} size="large" />
    <div className="ml-4 flex-1">
      <h1 className="text-3xl font-bold text-snow-white font-playfair-display mb-2 text-left">
        {name}
      </h1>
      <p className="text-sm text-snow-white font-geist mb-2 text-left">
        Created by {creator}
      </p>
      {description && (
        <p className="text-sm text-snow-white font-geist text-left">
          {description}
        </p>
      )}
    </div>
  </div>
);

const AlbumGrid = ({ images }) => (
  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
    {images.map((image, index) => (
      <div key={index} className="aspect-w-1 aspect-h-1">
        <img
          src={image}
          alt={`${index + 1}`}
          className="object-cover w-full h-full rounded-lg"
        />
      </div>
    ))}
  </div>
);

const Album = () => {
  const { id } = useParams();
  const [albumData, setAlbumData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlbum = async () => {
      try {
        const data = await api.get(APIEndpoints.album(id)); // Use the ID from useParams
        setAlbumData(data);
        console.log('Fetched album data:', data);
      } catch (err) {
        setError('Failed to fetch album data');
        console.error('Error fetching album:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAlbum();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!albumData) return <div>No album data found</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <AlbumHeader
        name={albumData.name}
        creator={`${albumData.owner.first_name} ${albumData.owner.last_name}`}
        profilePic={albumData.owner.profile_picture_url}
        description={albumData.description}
      />
      <AlbumGrid images={albumData.images.map((image) => image.url)} />

      <div className="mt-8 flex flex-col items-center">
        <p className="text-lg text-snow-white font-geist mb-4">
          view the entire album on the app
        </p>
        <AppStoreBadge />
      </div>
    </div>
  );
};

export default Album;

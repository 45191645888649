import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';

const Footer = () => (
  <footer className="bg-black text-snow-white py-6 px-4">
    <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
      <Link to="/" className="mb-4 md:mb-0">
        <img src={logo} alt="App Logo" className="logo h-8" />
      </Link>
      <nav className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0">
        <Link to="/privacy-policy" className="site-link">privacy policy</Link>
        <Link to="/about" className="site-link md:ml-4">about</Link>
      </nav>
    </div>
  </footer>
);

export default Footer;
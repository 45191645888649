import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <Header />
    <main className="bg-black flex-grow flex flex-col items-center text-center p-1 box-border">
      {children}
    </main>
    <Footer />
  </div>
);

export default Layout;

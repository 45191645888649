import React from 'react';

function SplashNoCard({ children }) {
  return (
    <div className="py-12 m-4">
      <div className="flex flex-col items-center justify-center w-full px-4">
        {children}
      </div>
    </div>
  );
}

export default SplashNoCard;
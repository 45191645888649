import React from 'react';

const ProfilePicture = ({ src, alt, size = 'medium' }) => {
  const sizeClasses = {
    small: 'w-10 h-10',
    medium: 'w-16 h-16',
    large: 'w-24 h-24'
  };

  return (
    <div className={`${sizeClasses[size]} rounded-full overflow-hidden `}>
      <img src={src} alt={alt} className="w-full h-full object-cover" />
    </div>
  );
};

export default ProfilePicture;
import React, { useState, useEffect } from 'react';

import rollingTextImage1 from '../images/family-reunion.jpg';
import rollingTextImage2 from '../images/girls-night.jpg';
import rollingTextImage3 from '../images/baecation.jpg';

const defaultRollingTextImages = [
  rollingTextImage1,
  rollingTextImage2,
  rollingTextImage3,
];

function SpashRollingTextSection({
  rollingSectionSubHeader = 'post photos from your',
  rollingSectionAlbumNames = ['family reunion', 'night out', 'vacation'],
  rollingTextImages = defaultRollingTextImages,
}) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const interval = 3000;

  useEffect(() => {
    const textInterval = setInterval(() => {
      setCurrentTextIndex(
        (prevIndex) => (prevIndex + 1) % rollingSectionAlbumNames.length
      );
    }, interval); // Change text every 3 seconds

    const imageInterval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % rollingTextImages.length
      );
    }, interval); // Change image every 5 seconds

    return () => {
      clearInterval(textInterval);
      clearInterval(imageInterval);
    };
  }, [rollingSectionAlbumNames.length, rollingTextImages.length]);

  return (
    <div className="sync-container">
      <p className="header text-snow-white">{rollingSectionSubHeader}</p>
      <div className="rolling-text-container m-4">
        <div className="rolling-text text-snow-white">
          <span>{rollingSectionAlbumNames[currentTextIndex]}</span>
        </div>
      </div>
      <div className="flex justify-center items-center m-2">
        <div className="photo-container-lg relative">
          {rollingTextImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Rolling Text ${index + 1}`}
              className={`photo image-slide ${
                index === currentImageIndex ? 'active' : ''
              }`}
              style={{
                opacity: index === currentImageIndex ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SpashRollingTextSection;

import React from 'react';
import AppStoreBadge from './AppStoreBadge';

const SplashPhotos = () => (
  <div className="photos-container-sm flex justify-center items-center m-4">
    <div className="photo-container-sm relative">
      <div className="photo photo-1 photo-animation"></div>
    </div>
    <div className="photo-container-sm relative">
      <div className="photo photo-2 photo-animation"></div>
    </div>
    <div className="photo-container-sm relative">
      <div className="photo photo-3 photo-animation"></div>
    </div>
  </div>
);

function SplashMainCard({ mainCardSubheader1 }) {
  return (
    <>
      <SplashPhotos />
      <div className="content flex flex-col items-center w-full text-snow-white">
        <p className="header mb-4">an authentic way to share albums</p>
      </div>
      <AppStoreBadge />
    </>
  );
}

export default SplashMainCard;

import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://hot-prod.fly.dev'
  : 'http://localhost:4000';

const API_KEY = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_HOT_API_PUBLIC_KEY_PROD
  : process.env.REACT_APP_HOT_API_PUBLIC_KEY_DEV;

const APIEndpoints = {
  album: (id) => `/album/public/?album_id=${id}`,
  user: (id) => `/users/${id}`,
};

class ApiService {
  constructor(baseUrl = API_BASE_URL, apiKey = API_KEY) {
    this.baseUrl = baseUrl;
    this.apiKey = apiKey;
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': this.apiKey,
      },
    });
  }

  buildUrl(endpoint, queryParams = {}) {
    const url = typeof endpoint === 'function' ? endpoint() : endpoint;
    return { url, params: queryParams };
  }

  async request(
    endpoint,
    method = 'GET',
    queryParams = {},
    data = null,
    customConfig = {}
  ) {
    const { url, params } = this.buildUrl(endpoint, queryParams);

    const config = {
      method,
      url,
      params,
      data,
      ...customConfig,
    };

    try {
      const response = await this.axiosInstance(config);
      return response.data;
    } catch (error) {
      console.error(
        'API request failed:',
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  }

  get(endpoint, queryParams = {}, customConfig = {}) {
    return this.request(endpoint, 'GET', queryParams, null, customConfig);
  }

  post(endpoint, data, queryParams = {}, customConfig = {}) {
    return this.request(endpoint, 'POST', queryParams, data, customConfig);
  }

  put(endpoint, data, queryParams = {}, customConfig = {}) {
    return this.request(endpoint, 'PUT', queryParams, data, customConfig);
  }

  delete(endpoint, queryParams = {}, customConfig = {}) {
    return this.request(endpoint, 'DELETE', queryParams, null, customConfig);
  }
}

const api = new ApiService();

export { api, APIEndpoints };

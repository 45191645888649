import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import Home from './Home';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import Album from './Album';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/a/:id" element={<Album />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;